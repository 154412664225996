<template>
  <div class="container" fluid="sm">  
    <h3 class="mt-4">Client</h3>

    <b-alert variant="danger" :show="this.tel == null">Aucun client</b-alert>

    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-card>
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </template>
      <b-card v-for="client in organisations" :key="client.id_organisation" header-bg-variant="primary" class="mb-3">
          <template v-slot:header>
            <div class="text-white">
              <h6 v-if="client" class="mb-0 align-items-center">{{client.nom_contact}}</h6>
              <small class="mr-1 text_wrap"><b-badge variant="warning">{{ client.libelle_activite_organisation}}</b-badge></small>
            </div>
          </template>

          <b-card-body>
              <div class="d-flex w-100 justify-content-between">
              </div>
                <p class="mb-1">Raison sociale : {{ client.raison_sociale}}</p>
                <p class="mb-1">Nom : {{ client.nom_contact}}</p>
                <p class="mb-1">Prénom : {{ client.prenom_contact}}</p>
                <p class="mb-1">Adresse : {{ client.adresse}}</p>
                <p class="mb-1">Code Postal : {{ client.code_postal}}</p>
                <p class="mb-1">Ville : {{ client.ville}}</p>
            </b-card-body> 
            <template v-slot:footer>
                <div class="d-flex w-100 justify-content-end">
                  <b-button :to="`/client/${client.id_organisation}/situation/`" variant="primary" size="sm">Situation</b-button>
                </div>
            </template> 
        </b-card>      
    </b-skeleton-wrapper>

  </div>
</template>

<script>

import ClientService from '../services/ClientService'

  export default {
    name: "MClient",
    data() {
      return {
        organisations : [],
        organisation : {},
        tel : null,
        loading: false,
      }
    },
    computed : {
      idOrganisation() {
        return this.$store.getters.user.id_organisation
      },
      user() {
        return this.$store.state.auth.user
      },
    },
    methods : {
      async loadClient(){

    //     this.organisations = [
    //     {
    //         "id_organisation": "22",
    //         "code_organisation_prospect": "C_COL_2006/529",
    //         "code_organisation": "527",
    //         "code_organisation_externe": null,
    //         "cible_orga": null,
    //         "libelle_origine_affaire": "Non spécifié",
    //         "fk_workflow_statut": "445",
    //         "libelle_workflow_statut": "CLIENT",
    //         "indic_statut_organisation": "1",
    //         "indic_sens_wkf": "1",
    //         "date_workflow_statut": "08/06/2020 14:35",
    //         "fk_user_membre_creat": "8",
    //         "nom_creat": "COLAS",
    //         "prenom_creat": "Romain",
    //         "fk_user_membre_affecte": "0",
    //         "nom_affecte": "AUCUN",
    //         "prenom_affecte": "Aucun",
    //         "code_user_group": "GENERAL GROUP",
    //         "siren": null,
    //         "code_ape": null,
    //         "genre_activite": "Client",
    //         "raison_sociale": "BARRET Christian",
    //         "fk_activite_organisation": "2",
    //         "nom_contact": "BARRET Christian",
    //         "prenom_contact": "",
    //         "nom_contact_fonction": null,
    //         "code_postal": "48170",
    //         "zone": "",
    //         "ville": "Saint-Frezal",
    //         "fk_pays": "1",
    //         "email": "",
    //         "date_creation": "08/06/2020",
    //         "libelle_activite_organisation": "Particulier ",
    //         "libelle_pays": "France"
    //     },
    //     {
    //         "id_organisation": "53",
    //         "code_organisation_prospect": "C_GON_2006/556",
    //         "code_organisation": "554",
    //         "code_organisation_externe": "24107301",
    //         "cible_orga": null,
    //         "libelle_origine_affaire": "Non spécifié",
    //         "fk_workflow_statut": "445",
    //         "libelle_workflow_statut": "CLIENT",
    //         "indic_statut_organisation": "1",
    //         "indic_sens_wkf": "1",
    //         "date_workflow_statut": "22/06/2020 15:15",
    //         "fk_user_membre_creat": "10",
    //         "nom_creat": "JOUAN",
    //         "prenom_creat": "Valérie",
    //         "fk_user_membre_affecte": "10",
    //         "nom_affecte": "JOUAN",
    //         "prenom_affecte": "Valérie",
    //         "code_user_group": "GENERAL GROUP",
    //         "siren": null,
    //         "code_ape": null,
    //         "genre_activite": "Client",
    //         "raison_sociale": "CYGELMAN Roger et Annie",
    //         "fk_activite_organisation": "2",
    //         "nom_contact": "CYGELMAN Roger et Annie",
    //         "prenom_contact": "",
    //         "nom_contact_fonction": null,
    //         "code_postal": "05350",
    //         "zone": "",
    //         "ville": "ARVIEUX",
    //         "fk_pays": "1",
    //         "email": "",
    //         "date_creation": "22/06/2020",
    //         "libelle_activite_organisation": "Particulier ",
    //         "libelle_pays": "France"
    //     }
    // ]

    
    this.loading = false
        try {
          this.loading = true;
          const result = await ClientService.GetOrganisationByTel('0102030405')
          this.loading = false
          if(result.data.statut == 'OK') {
           if(result.data.nbre == 1) {
              this.$router.push(`/client/${result.data.list_orga[0]}/situation/`)
           }

            this.organisations = result.data.list_orga
            console.log(result.data)
          }         
        } catch (error) {
          this.loading = false
            console.log(error)
        }
      },
    },
    mounted() {

      if(this.$route.params.tel) {
        this.tel = this.$route.params.tel
      }
      this.loading = true
      this.loadClient()
    }
  }
</script>


<style>

</style>