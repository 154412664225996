<template>
	<div class="d-flex justify-content-start align-items-start">

            <span :class="status == 'inprocess' ? 'font-weight-bold' : 'font-weight-normal'">{{ name }}</span>
            <span class="ml-3"><b-icon v-show="status == 'inprocess'" icon="three-dots" animation="cylon" font-scale="1"></b-icon></span>
            <span class="ml-3"><b-icon v-show="status == 'completed'" icon="check-circle-fill" variant="success" font-scale="1"></b-icon></span>
            <span class="ml-3"><b-icon v-show="status == 'error'" icon="exclamation-circle-fill" variant="danger" font-scale="1"></b-icon></span>
      </div>
  </template>

  <script>

 
  export default {
	name: "RequestStatus",
    components: {
        
    },
	props: {
        status: { type: String, default: "waiting" },
        name: {type: String, default: ""}
	},
	data() {
		return {
			
		};
	},
	methods: {
		
	}
  };
  </script>
  
  <style>
   </style>
  