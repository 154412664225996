<template>
	<div class="d-stepper">
		<div class="d-stepper-header d-flex justify-content-around">
			<div class="step-number-content text-center" :class="{ active: step == i}" v-for="(stage, i) in stages" :key="i">
			<div class="step-number align-items-center justify-content-center mx-auto" :class="stepNumberClasses(i)">
				<i v-if="step > i" class="fas fa-check"><b-icon icon="check" scale="2"></b-icon></i>
				
				<i v-else-if="step === i && fatalError" class="fas fa-exclamation"></i>
				<span v-else>{{ i + 1 }}</span>
			</div>
			<!-- <div v-if="step > i" class="mt-1 small"><a href="#" @click="goStage(i)">{{ stage.name }}</a></div> -->
			<div  class="mt-1 small">{{ stage.name }}</div>
		</div>
	</div>
	<!-- activeStep : {{ activeStep }}
	step : {{ step }} -->
	<b-card class="my-4" bg-variant="light" no-body :class="{ 'border-danger': error, 'shake-error': shake }">
		<b-card-body class="d-flex justify-content-start align-items-stretch">
			<div v-if="activeStep && activeStep.icon" class="d-none d-sm-block">
				<i class="fas fa-fw fa-3x mr-4" :class="iconClasses"></i>
			</div>
					
			<div v-if="!fatalError && activeStep" class="flex-grow-1">
				<h3>{{ activeStep.id }}. {{ activeStep.name }}</h3>
				<!-- <p class="text-muted">{{ activeStep.selected }}</p> -->

				<div v-if="activeStep.type && activeStep.type=='radio'">
					<b-form-radio-group
						v-model="activeStep.selected"
						:options="activeStep.list_values"
						button-variant="outline-primary"
						buttons
						@change="nextStep"
					></b-form-radio-group>
				</div>

				<Transition :name="effect" mode="out-in">
					<validation-observer ref="form" v-slot="{ handleSubmit }">
						<b-form @submit.stop.prevent="handleSubmit(onSubmit)">
							<BlocItem v-for="bloc in activeStep.form" :key="bloc.id" :bloc="bloc" @clicked="nextStep"></BlocItem>
						</b-form>
					</validation-observer>
				</Transition>

				<Transition :name="effect" mode="out-in" tag="keep-alive">

					<keep-alive>
						<component v-if="subStepComponent" :is="subStepComponent" />
					</keep-alive>
					
				</Transition>
			</div>
			<div v-else>{{ fatalErrorMsg }}</div>
		</b-card-body>
	</b-card>
 
		<div class="d-flex justify-content-end" v-if="!fatalError && activeStep">
			<b-button v-if="history_steps.length > 0" variant="light" :disabled="loading" class="text-primary" @click="backStep"><i class="fas fa-angle-double-left"></i> Retour</b-button>
			<b-button v-if="activeStep.type || activeStep.nextId != null" variant="primary"	class="ml-2" @click="nextStep" :disabled="loading">	Suivant	<i class="fas fa-light fa-angles-right"></i></b-button>
			<!-- <b-button v-if="stages[step].confirm" variant="success" class="ml-2" @click="$emit('confirm')">{{ stages[step].confirm }}</b-button> -->
		</div>
	</div>
  </template>

  <script>

  import BlocItem from '../components/BlocItem'
  export default {
	name: "DStepper",
  components: {
    BlocItem
  },
	props: {
		stages: { type: Array, default: () => [] },
	},
	data() {
		return {
			stepId: 1,
			nextStepId :null,
			step: 0,
			activeStep : null,
			loading: false,
			error: false,
			fatalError: false,
			fatalErrorMsg: "",
			shake:false,
			effect: "in-out-translate-fade",
			history_steps: [],
		};
	},
	mounted() {
		this.activeStep = this.getStep(this.stepId);
		console.log(this.activeStep)
	},
	computed: {
		subStepComponent() {
			return this.activeStep.component;
		},
		iconClasses() {
			if (!this.activeStep.icon) return "";
			else if (/\s/.test(this.activeStep.icon)) return this.activeStep.icon;
			return `fas ${this.activeStep.icon}`;
		}
	},
	methods: {
		goStage(stageId) {
			this.activeStep = this.stages[stageId].steps[0]
			this.step = 0
		},
		getStep(id) {
			let index = 0
			for (const stage of  this.stages) {
				const step = stage.steps.find(step => step.id == id)
				if(step){
					this.step = index
					return step
				}
				index++
			}
		},
		resetParams() {
			this.error = false;
			this.loading = false;
			this.fatalErrorMsg = "";
			this.fatalError = false;
		},
		stepNumberClasses(i) {
			return {
				"bg-primary text-white": this.step === i && !this.fatalError,
				"bg-success text-white": this.step > i && !this.fatalerror,
				"bg-danger text-white": this.fatalError && this.step === i,
				"text-primary": this.step < i
			};
		},
		async nextStep() {

			const success = await this.$refs.form.validate()
			if (!success) {
				console.log(2)
				return
			}
			else {
				console.log("Success")
			}

			if (!this.$refs.step?.nextStep) return this.nextStepAction();
	
			if (this.$refs.step.nextStep()) {
				if (!this.loading) {
					this.nextStepAction();
				}
			}
		},
		nextStepAction() {
			this.effect = "in-out-translate-fade";
			this.resetParams();

			this.history_steps.push(this.activeStep.id)

			if(this.activeStep.type=="radio"){
				this.stepId = this.activeStep.selected.stepId
			}
			else {
				this.stepId = this.activeStep.nextId
			}

			this.activeStep = this.getStep(this.stepId);

		},
		backStep() {
			this.effect = "out-in-translate-fade";
			this.resetParams();
			if ( this.history_steps.length > 0) {
				console.log(this.history_steps)	
				this.stepId =  this.history_steps.pop();
				this.activeStep = this.getStep(this.stepId);
			}
		},
		onSubmit() {
			alert("Form submitted!");
		}
	}
  };
  </script>
  
  <style>
  .d-stepper .d-stepper-header {
	max-width: 600px;
	margin: 0 auto;
	position: relative;
  }
  
  .d-stepper .d-stepper-header::before {
	position: absolute;
	width: 100%;
	height: 1px;
	background: #DDD;
	top: 20px;
	left: 0;
	content: " ";
  }
  
  .d-stepper .step-number {
	background: #E9E9E9;
	border-radius: 50%;
	text-align: center;
	height: 40px;
	width: 40px;
	display: flex;
  }
  .d-stepper .step-number-content {
	transition: transform 0.2s;
	z-index: 2;
	width: 68px;
  }
  
  .d-stepper .step-number-content div {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
  }
  .d-stepper .step-number-content.active {
	transform: scale(1.25);
  }
  
  .in-out-translate-fade-enter-active,
  .in-out-translate-fade-leave-active {
	transition: all 0.15s;
  }
  .in-out-translate-fade-enter,
  .in-out-translate-fade-leave-active {
	opacity: 0;
  }
  .in-out-translate-fade-enter {
	transform: translateX(100px);
  }
  .in-out-translate-fade-leave-active {
	transform: translateX(-100px);
  }
  
  .out-in-translate-fade-enter-active,
  .out-in-translate-fade-leave-active {
	transition: all 0.15s;
  }
  .out-in-translate-fade-enter,
  .out-in-translate-fade-leave-active {
	opacity: 0;
  }
  .out-in-translate-fade-enter {
	transform: translateX(-100px);
  }
  .out-in-translate-fade-leave-active {
	transform: translateX(100px);
  }
  </style>
  