<template>
  <div v-if="question">
        <h5 v-if="question.question_type =='section'">{{question.title}}</h5>
        <SingleFileUpload v-if="question.question_type =='uploadDoc'" :id="question.comment" :label="question.title" :blocId="blocId" :questionId=question.id ></SingleFileUpload>
                  
        <validation-provider
								:name="question.title"
								:rules="question.validation"
								v-slot="validationContext"
				>
          <div v-if="question.question_type == 'inputText'">

								<b-form-group :label="question.title" :label-for="question.comment">
									<b-form-input
                    :id="question.comment"
                    :name="question.comment"
                    v-model="question.value" 
                    :state="getValidationState(validationContext)"
                    :type="question.value_type"
                    :aria-describedby="`${question.comment}-feedback`"
									></b-form-input>

									<b-form-invalid-feedback :id="`${question.comment}-feedback`">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
								</b-form-group>
          </div>
        
        <div v-if="question.question_type == 'radio'">
          <b-form-group :label="question.title" :label-for="question.comment">
            <b-form-radio-group :id="question.comment" v-model="question.value"  :options="question.list_values" :state="getValidationState(validationContext)" :aria-describedby="`${question.comment}-feedback`"></b-form-radio-group>
          </b-form-group>
          <b-form-invalid-feedback :id="`${question.comment}-feedback`">{{ validationContext.errors[0] }}</b-form-invalid-feedback>

        </div>  
      
        <div v-if="question.question_type == 'select'">
          <b-form-group :label="question.title" :label-for="question.comment">
            <b-form-select :id="question.comment" v-model="question.value"  :options="question.list_values" :state="getValidationState(validationContext)" :aria-describedby="`${question.comment}-feedback`"></b-form-select>
          </b-form-group>
          <b-form-invalid-feedback :id="`${question.comment}-feedback`">{{ validationContext.errors[0] }}</b-form-invalid-feedback>

        </div>

        <div v-if="question.question_type == 'checklist'">
          <b-form-group :label="question.title" :label-for="question.comment">
            <b-form-checkbox-group :id="question.comment" v-model="question.value"  :options="question.list_values" stacked :state="getValidationState(validationContext)" :aria-describedby="`${question.comment}-feedback`"></b-form-checkbox-group>
          </b-form-group>
          <b-form-invalid-feedback :id="`${question.comment}-feedback`">{{ validationContext.errors[0] }}</b-form-invalid-feedback>

        </div>

        <div v-if="question.question_type == 'date'">
          <b-form-group :label="question.title" :label-for="question.comment">
            <b-form-datepicker :id="question.comment"  v-model="question.value" :state="getValidationState(validationContext)" :aria-describedby="`${question.comment}-feedback`"></b-form-datepicker>
          </b-form-group>
          <b-form-invalid-feedback :id="`${question.comment}-feedback`">{{ validationContext.errors[0] }}</b-form-invalid-feedback>

        </div>

        <div v-if="question.question_type == 'time'">
          <b-form-group :label="question.title" :label-for="question.comment">
            <b-form-timepicker :id="question.comment" v-model="question.value" :state="getValidationState(validationContext)" :aria-describedby="`${question.comment}-feedback`"></b-form-timepicker>
          </b-form-group>
          <b-form-invalid-feedback :id="`${question.comment}-feedback`">{{ validationContext.errors[0] }}</b-form-invalid-feedback>

        </div>

        <div v-if="question.question_type == 'textarea'">
          <b-form-group :label="question.title" :label-for="question.comment">
            <b-form-textarea :id="question.comment" v-model="question.value"  rows="3" max-rows="6" :state="getValidationState(validationContext)" :aria-describedby="`${question.comment}-feedback`"></b-form-textarea>
          </b-form-group>
          <b-form-invalid-feedback :id="`${question.comment}-feedback`">{{ validationContext.errors[0] }}</b-form-invalid-feedback>

        </div>

        <div v-if="question.sub_questions.length > 0">
          <div v-for="subquestion in question.sub_questions" :key="subquestion.id">
            <QuestionItem  v-if="subquestion.selected_parent_value == question.value" :question="subquestion" :blocId="subquestion.id"></QuestionItem>
          </div>         
        </div>
      </validation-provider>
  </div>
</template>

<script>
import SingleFileUpload from "../components/SingleFileUpload.vue"


export default {
  name: 'QuestionItem',
  components: {
      SingleFileUpload
  },
  props: ['question','blocId'],
  data: () => ({
    fatalErrorMsg : ""
  }),
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  }
}
</script>

<style>

</style>