<template>
  <div>
    <b-form-row>
      <b-button block variant="primary" @click="creerDossier" class="mb-5">Creation du dossier d'affaire</b-button>
    </b-form-row>
    <b-form-row v-for="request, index in requests" :key="index">
      <RequestStatus :status="request.status" :name="request.name"></RequestStatus>
    </b-form-row>
  </div>
</template>

<script>
import ClientService from '../services/ClientService'
import DossierService from '../services/DossierService'
import RequestStatus from './RequestStatus.vue'

export default {
  name: 'CreationDossier',
  components: {
    RequestStatus
},
  props: ["informations","blocs","itemRequired"],
  data: () => ({
    requests : [
      {
          name:"Creation du client",
          status:"waiting"
      },
      {
          name:"Creation du dossier",
          status:"waiting"
      },
      // {
      //     name:"Creation des articles",
      //     status:"waiting"
      // },
      {
          name:"Creation de la VT",
          status:"waiting"
      },
  ],
    show : false,
  }),
  mounted() {
    
  },
  computed: {
    id_user_membre() {
       return this.$store.state.auth.user.id_user_membre
    },
    workflow () {
      return this.$store.state.workflow.stages
    },
    contact () {
      return this.$store.state.workflow.stages[0].steps
    },
    installation () {
      return this.$store.state.workflow.stages[1].steps
    },
    isEntreprise () {
      return this.contact[0].selected.text == "Oui"
    },
    entrepriseInfo () {
      return {
        siret : this.contact[1].form[0].questions[0].value,
        raison_sociale : this.contact[1].form[0].questions[1].value,
        adresse : this.contact[1].form[0].questions[2].value,
        cp : this.contact[1].form[0].questions[3].value,
        ville : this.contact[1].form[0].questions[4].value,
        pdl : this.contact[1].form[0].questions[5].value
      }
    },
    clientInfo () {
      return {
        civilite : this.contact[2].form[0].questions[0].value,
        nom : this.contact[2].form[0].questions[1].value,
        prenom : this.contact[2].form[0].questions[2].value,
        telephone : this.contact[2].form[0].questions[3].value,
        email : this.contact[2].form[0].questions[4].value,
        adresse : this.contact[2].form[0].questions[5].value,
        codePostal : this.contact[2].form[0].questions[6].value,
        ville : this.contact[2].form[0].questions[7].value,
        pdl : this.contact[2].form[0].questions[8].value,
      }
    },
    contactInfo () {
      return {
        civilite : this.contact[this.memeAdresse ? 2 : 4].form[0].questions[0].value,
        nom : this.contact[this.memeAdresse ? 2 : 4].form[0].questions[1].value,
        prenom : this.contact[this.memeAdresse ? 2 : 4].form[0].questions[2].value,
        telephone : this.contact[this.memeAdresse ? 2 : 4].form[0].questions[3].value,
        email : this.contact[this.memeAdresse ? 2 : 4].form[0].questions[4].value,
        adresse : this.contact[this.memeAdresse ? 2 : 4].form[0].questions[5].value,
        codePostal : this.contact[this.memeAdresse ? 2 : 4].form[0].questions[6].value,
        ville : this.contact[this.memeAdresse ? 2 : 4].form[0].questions[7].value,
      }
    },
    memeAdresse () {
      return this.contact[3].selected.text == "Oui"
    },
    puissance_souscrite () {
      return this.installation[3].form[0].questions[0].value
    }
 
  },
  methods: {
    newCustomer(numTransaction) {
      return {
        code_app : "APP_COMMERCIALE",
        numTransaction : numTransaction,
        id_user_membre : this.id_user_membre,
        typeop : this.isEntreprise ? 2 : 1,
        statutOrga : 1,
        id_origine_affaire_ecommerce : 500,
        id_secteur_organisation : 0,
        siren : this.isEntreprise ? this.entrepriseInfo.siret : "", 
        raison_sociale : this.isEntreprise ? this.entrepriseInfo.raison_sociale : "",
        nom : this.clientInfo.nom,
        prenom : this.clientInfo.prenom,
        adresse : this.isEntreprise ? this.entrepriseInfo.adresse : this.clientInfo.adresse,
        codePostal : this.isEntreprise ? this.entrepriseInfo.codePostal : this.clientInfo.codePostal,
        ville : this.isEntreprise ? this.entrepriseInfo.ville : this.clientInfo.ville,
        id_pays : 1,
        mobile: null,
        telephone: this.clientInfo.telephone,
        email : this.clientInfo.email,
        puissance_souscrite : this.puissance_souscrite,
        //Contact
        liv_raison_sociale : "",
        liv_adresse : this.contactInfo.adresse,
        liv_code_postal : this.contactInfo.codePostal,
        liv_ville : this.contactInfo.ville,
        liv_fk_pays : 1,
        liv_contact : this.contactInfo.nom,
        liv_contact_prenom : this.contactInfo.prenom,
        liv_num_tel_1 : null,
        liv_num_tel_2 : this.contactInfo.telephone,
        liv_email : this.contactInfo.email
      }
    },
    newDossier(numTransaction, titre_dossier, id_organisation_client) {
      return {
            code_app : "APP_DOSSIER",
            numTransaction : numTransaction,
            id_user_membre : this.id_user_membre,
            id_organisation_client : id_organisation_client,
            id_type_dossier : 1, // e-batterie
            id_regie: 0,
            titre_dossier : titre_dossier,
            bool_qui_facturer : 0,
            id_moe : 1,
            pdl : this.isEntreprise ? this.entrepriseInfo.pdl : this.clientInfo.pdl,
            puissance_souscrite : this.puissance_souscrite,
            observation_dossier:"Dossier créé par l'APP CTI TEAMBOX"
          }
    },

    newVT(numTransaction, idDossier) {
      return {
          code_app : "APP_DOSSIER",
          numTransaction : numTransaction,
          id_user_membre : this.id_user_membre,
          module: "DOSSIER_AFFAIRE",
          id_model_VT : 2,
          idOrganisation : 0,
          idDossier : idDossier,
          modeVT : "VIEW",
          batiment : "",
          detail_emplacement : "",
          jsonVtString : JSON.stringify(this.workflow)
        }
    },

  async creerDossier() { 


    let numTransaction = Math.floor(Math.random() * 100000) + 1;
    let customer = this.newCustomer(numTransaction)
    console.log(customer)
    try {
      this.requests[0].status = "inprocess"
      let result = await ClientService.CreateCustomer(customer)
      console.log(result)
      if(result.data.status =="NOK") {
        console.log(result.data.message_specifique)
        this.requests[0].status = "error"
        return
      }
      else {
        this.requests[0].status = "completed"
      }

      let titre_dossier = this.isEntreprise ? `${this.entrepriseInfo.raison_sociale} ` : `${this.clientInfo.nom} ${this.clientInfo.prenom} `
      
      this.requests[1].status = "inprocess"
      result = await DossierService.CreateDossier(this.newDossier(numTransaction, titre_dossier, result.data.id_fiche) )

      if(result.data.status =="NOK") {
        console.log(result.data.message_specifique)
        this.requests[1].status = "error"
        return
      }
      else {
        this.requests[1].status = "completed"
      }

      this.requests[2].status = "inprocess"
      result = await DossierService.CreateVT(this.newVT(numTransaction, result.data.id_fiche ))

      if(result.data.status =="NOK") {
        console.log(result.data.message_specifique)
        this.requests[2].status = "error"
        return
      }
      else {
        this.requests[2].status = "completed"
      }


    } catch (error) {
      this.$bvModal.msgBoxOk(`Error : ${error.message}`, {
              title: 'Erreur',
              okVariant: 'danger',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
    }
  }
    }
  }

</script>

<style>

</style>