export default function authHeader() {
    let user = JSON.parse(localStorage.getItem('user'));
    //DEV
    //user.accessToken ='aa7be927-3acf-44ff-8194-c8f48ae4c757'
    //PROD
    user.accessToken ='27f1eb55-e252-4c67-8b27-099b688c7c9f'

    //if (user && user.accessToken) {
      //return { Authorization: 'Bearer ' + user.accessToken };
    //} else {
      return {'Content-Type': 'application/json'};
    //}
  }