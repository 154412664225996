<template>
  <div class="container" fluid="sm">  
    <h3 class="mt-4">Situation</h3>

    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-card>
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </template>
      <b-card  header-bg-variant="primary" class="mb-3">
          <template v-slot:header>
            <div  v-if="situation.data_orga" class="text-white">
              <h6  class="mb-0 align-items-center">{{situation.data_orga.nom_contact}}</h6>
              <small class="mr-1 text_wrap"><b-badge variant="warning">{{ situation.data_orga.libelle_activite_organisation}}</b-badge></small>
            </div>
          </template>

          <b-card-body>
              <div class="d-flex w-100 justify-content-between">
              </div>
              <div v-if="situation.data_orga">
                <p class="mb-1">Raison sociale : {{ situation.data_orga.raison_sociale}}</p>
                <p class="mb-1">Nom : {{ situation.data_orga.nom_contact}}</p>
                <p class="mb-1">Prénom : {{ situation.data_orga.prenom_contact}}</p>
                <p class="mb-1">Adresse : {{ situation.data_orga.adresse}}</p>
                <p class="mb-1">Code Postal : {{ situation.data_orga.code_postal}}</p>
                <p class="mb-1">Ville : {{ situation.data_orga.ville}}</p>

                <p> {{ situation}}</p>

              </div>
            </b-card-body> 
            
        </b-card>      
    </b-skeleton-wrapper>

  </div>
</template>

<script>

import ClientService from '../services/ClientService'

  export default {
    name: "MSituation",
    data() {
      return {
        situation : {},
        loading: false,
      }
    },
    computed : {
      user() {
        return this.$store.state.auth.user
      },
    },
    methods : {
      async loadSituation(idOrganisation){
  
    this.loading = false
        try {
          this.loading = true;
          const result = await ClientService.GetSituation(idOrganisation)
//           const result = {
//     "statut": "OK",
//     "error_number": "0",
//     "error_message": "",
//     "message_specifique": "",
//     "nbre": 0,
//     "data_situation": {
//         "statut": "OK",
//         "error_number": "0",
//         "error_message": "",
//         "message_specifique": "",
//         "data_orga": {
//             "id_organisation": "53",
//             "fk_organisation_parent": null,
//             "raison_sociale": "CYGELMAN Roger et Annie",
//             "siren": null,
//             "code_ape": null,
//             "genre_activite": "Client",
//             "fk_type_offre_premier": "2",
//             "fk_activite_organisation": "2",
//             "libelle_activite_organisation": "Particulier ",
//             "categorie_activite": "PARTICULIER",
//             "bloc_bareme_droit": "0",
//             "bloc_bareme_view": null,
//             "fk_secteur_organisation": "0",
//             "libelle_secteur_organisation": "0-Aucun secteur",
//             "code_organisation_prospect": "C_GON_2006/556",
//             "code_organisation": "554",
//             "code_organisation_externe": "24107301",
//             "fk_workflow_statut": "445",
//             "libelle_workflow_statut": "CLIENT",
//             "date_workflow_statut": "22/06/2020 15:15",
//             "fk_user_membre_affecte": "10",
//             "nom_affecte": "JOUAN",
//             "prenom_affecte": "Valérie",
//             "fk_groupe_affecte": "1",
//             "code_user_group": "GENERAL GROUP",
//             "fk_user_membre_creat": "10",
//             "nom_creat": "JOUAN",
//             "prenom_creat": "Valérie",
//             "fk_origine_affaire_orga": "0",
//             "libelle_origine_affaire": "Non spécifié",
//             "cible_orga": null,
//             "fichier_import": null,
//             "nom_contact": "CYGELMAN Roger et Annie",
//             "prenom_contact": "",
//             "nom_contact_fonction": null,
//             "civilite": null,
//             "contact_date_naissance": null,
//             "contact_ville_naissance": "",
//             "contact_cp_naissance": "",
//             "contact_pays_naissance": "",
//             "destinataire": "",
//             "adresse": "229 Route de Clapeyto",
//             "code_postal": "05350",
//             "zone": "",
//             "region": "",
//             "ville": "ARVIEUX",
//             "fk_pays": "1",
//             "libelle_pays": "France",
//             "liv_raison_sociale": "",
//             "liv_siren_siret": null,
//             "liv_destinataire": "",
//             "liv_adresse": "229 Route de Clapeyto",
//             "liv_code_postal": "05350",
//             "liv_region": "",
//             "liv_ville": "ARVIEUX",
//             "liv_fk_pays": "1",
//             "liv_libelle_pays": "France",
//             "liv_contact": "",
//             "liv_contact_prenom": "",
//             "liv_contact_fonction": "",
//             "liv_num_tel_1": "0102030405",
//             "liv_num_tel_2": "",
//             "liv_num_fax": "",
//             "liv_email": "",
//             "liv_civilite": null,
//             "liv_contact_date_naissance": null,
//             "liv_contact_ville_naissance": "",
//             "liv_contact_cp_naissance": "",
//             "liv_contact_pays_naissance": "",
//             "num_tel_1": "",
//             "num_tel_2": "",
//             "num_fax": "",
//             "email": "",
//             "site_web": null,
//             "bool_refus_communication_email": "0",
//             "contact_1": null,
//             "fonction_1": null,
//             "tel1_1": null,
//             "email1_1": null,
//             "contact_2": null,
//             "fonction_2": null,
//             "tel2_1": null,
//             "email2_1": null,
//             "contact_3": null,
//             "fonction_3": null,
//             "tel3_1": null,
//             "email3_1": null,
//             "contact_4": null,
//             "fonction_4": null,
//             "tel4_1": null,
//             "email4_1": null,
//             "contact_5": null,
//             "fonction_5": null,
//             "tel5_1": null,
//             "email5_1": null,
//             "note_libre": "Fiche créée par l'utilisateur directement via le MODULE DOSSIER AFFAIRE. La fiche est passée directement à l'étape CLIENT.",
//             "commentaire": null,
//             "fiche_technique": null,
//             "lien_logo": "",
//             "o_actif": "1",
//             "indic_statut_organisation": "1",
//             "indic_sens_wkf": "1",
//             "date_creation": "22/06/2020 15:15",
//             "fk_organisation_facturation": "1",
//             "fk_mode_reglement_courant": "0",
//             "libelle_mode_reglement_courant": "Non spécifié",
//             "fk_grille_tarification": null,
//             "pourcent_tva_courant": "20.00",
//             "forme_juridique": null,
//             "date_immatriculation": null,
//             "capital": "0.00",
//             "effectif": "0",
//             "ca": "0.00",
//             "resultat": "0.00",
//             "num_police": null,
//             "decenal": null,
//             "label_QUALIPAC": null,
//             "label_QUALIPV": null,
//             "label_QUALISOL": null,
//             "label_LIBRE1": null,
//             "demat_url": null,
//             "demat_utilisateur": null,
//             "demat_code": null,
//             "encours": "0.00",
//             "num_tva": null,
//             "masquer_remise_pdf": "0",
//             "banque_nom": null,
//             "banque_titulaire": null,
//             "banque_adresse": null,
//             "banque_code": null,
//             "banque_guichet": null,
//             "banque_compte": null,
//             "banque_cle_rib": null,
//             "banque_iban": null,
//             "banque_bic": null,
//             "e_commerce_description_fr": null,
//             "e_commerce_description_en": null,
//             "e_commerce_icone": "0",
//             "fk_statut_rdv_visionneuse": null,
//             "fk_user_membre_lock_visionneuse": null
//         },
//         "data_orga_multisite": [
//             {
//                 "id_multisite": "37",
//                 "libelle_site": "FACTURATION P.",
//                 "fk_organisation": "53",
//                 "ms_raison_sociale": null,
//                 "ms_destinataire": null,
//                 "ms_adresse": null,
//                 "ms_code_postal": null,
//                 "ms_region": null,
//                 "ms_ville": null,
//                 "fk_pays": "0",
//                 "ms_libelle_pays": "Aucun",
//                 "ms_contact": null,
//                 "ms_contact_fonction": null,
//                 "ms_tel_1": null,
//                 "ms_tel_2": null,
//                 "ms_fax": null,
//                 "ms_email": null,
//                 "ms_plein_pied": "0",
//                 "ms_rdc": "0",
//                 "ms_aucune_marche": "0",
//                 "ms_ascenseur": "0",
//                 "ms_monte_charge": "0",
//                 "ms_etage": "0",
//                 "ms_portage_escalier": "0",
//                 "ms_horaire": null,
//                 "ms_commentaire": null,
//                 "lien_logo": null,
//                 "ms_actif": "1"
//             },
//             {
//                 "id_multisite": "38",
//                 "libelle_site": "LIVRAISON P.",
//                 "fk_organisation": "53",
//                 "ms_raison_sociale": null,
//                 "ms_destinataire": null,
//                 "ms_adresse": null,
//                 "ms_code_postal": null,
//                 "ms_region": null,
//                 "ms_ville": null,
//                 "fk_pays": "0",
//                 "ms_libelle_pays": "Aucun",
//                 "ms_contact": null,
//                 "ms_contact_fonction": null,
//                 "ms_tel_1": null,
//                 "ms_tel_2": null,
//                 "ms_fax": null,
//                 "ms_email": null,
//                 "ms_plein_pied": "0",
//                 "ms_rdc": "0",
//                 "ms_aucune_marche": "0",
//                 "ms_ascenseur": "0",
//                 "ms_monte_charge": "0",
//                 "ms_etage": "0",
//                 "ms_portage_escalier": "0",
//                 "ms_horaire": null,
//                 "ms_commentaire": null,
//                 "lien_logo": null,
//                 "ms_actif": "1"
//             },
//             {
//                 "id_multisite": "16663",
//                 "libelle_site": "PORTABILITE",
//                 "fk_organisation": "53",
//                 "ms_raison_sociale": "CYGELMAN Roger et Annie PORTA",
//                 "ms_destinataire": "",
//                 "ms_adresse": "fdsfdsfsdf",
//                 "ms_code_postal": "78787",
//                 "ms_region": "",
//                 "ms_ville": "dfdsf",
//                 "fk_pays": "0",
//                 "ms_libelle_pays": "Aucun",
//                 "ms_contact": "PORTA",
//                 "ms_contact_fonction": "",
//                 "ms_tel_1": "0755555555",
//                 "ms_tel_2": "",
//                 "ms_fax": "",
//                 "ms_email": "",
//                 "ms_plein_pied": "0",
//                 "ms_rdc": "0",
//                 "ms_aucune_marche": "0",
//                 "ms_ascenseur": "0",
//                 "ms_monte_charge": "0",
//                 "ms_etage": "0",
//                 "ms_portage_escalier": "0",
//                 "ms_horaire": "",
//                 "ms_commentaire": "",
//                 "lien_logo": null,
//                 "ms_actif": "1"
//             }
//         ],
//         "data_dossiers": [
//             {
//                 "id_multisite": "16663",
//                 "libelle_site": "PORTABILITE",
//                 "fk_organisation": "53",
//                 "ms_raison_sociale": "CYGELMAN Roger et Annie PORTA",
//                 "ms_destinataire": "",
//                 "ms_adresse": "fdsfdsfsdf",
//                 "ms_code_postal": "78787",
//                 "ms_region": "",
//                 "ms_ville": "dfdsf",
//                 "fk_pays": "0",
//                 "ms_libelle_pays": "Aucun",
//                 "ms_contact": "PORTA",
//                 "ms_contact_fonction": "",
//                 "ms_tel_1": "0755555555",
//                 "ms_tel_2": "",
//                 "ms_fax": "",
//                 "ms_email": "",
//                 "ms_plein_pied": "0",
//                 "ms_rdc": "0",
//                 "ms_aucune_marche": "0",
//                 "ms_ascenseur": "0",
//                 "ms_monte_charge": "0",
//                 "ms_etage": "0",
//                 "ms_portage_escalier": "0",
//                 "ms_horaire": "",
//                 "ms_commentaire": "",
//                 "lien_logo": null,
//                 "ms_actif": "1",
//                 "id_dossier": "47",
//                 "fk_type_dossier": "1",
//                 "libelle_type_dossier": "E-Batterie",
//                 "fk_workflow_statut": "640",
//                 "libelle_workflow_statut": " EBATTERIE ACTIVEE",
//                 "date_workflow_statut": "09/01/2023 13:59",
//                 "fk_client": "53",
//                 "raison_sociale": "CYGELMAN Roger et Annie",
//                 "nom_contact": "CYGELMAN Roger et Annie",
//                 "prenom_contact": "",
//                 "email": "",
//                 "code_postal": "05350",
//                 "fk_dossier_suivi_par": "0",
//                 "nom_affecte": "AUCUN",
//                 "prenom_affecte": "Aucun",
//                 "code_dossier": "47/GON_2206_EBATT",
//                 "titre_dossier": "CYGELMAN ROGER ET ANNIE",
//                 "fk_regie": "0",
//                 "fk_commercial": "10",
//                 "commentaire_regie": "",
//                 "fk_origine_affaire": "0",
//                 "libelle_origine_affaire": "Non spécifié",
//                 "montant_prime_dev_1": "0.00",
//                 "montant_prime_dev_2": "0.00",
//                 "montant_prime_dev_3": "0.00",
//                 "montant_prime_dev_4": "0.00",
//                 "montant_prime_dev_5": "0.00",
//                 "is_install_referent": "0",
//                 "type_prestation": "",
//                 "pourcentage_reussite": "0",
//                 "supplement": null,
//                 "bloc_dossier_chaineradio_1": null,
//                 "bloc_dossier_num_1": "0.00",
//                 "bloc_dossier_num_2": "349.00",
//                 "description_dossier": null,
//                 "observation_dossier": "",
//                 "d_actif": "1",
//                 "indic_statut_checklist": "0",
//                 "indic_statut_dossier": "1",
//                 "indic_sens_wkf": "2",
//                 "date_creation": "22/06/2020 15:15",
//                 "date_signature": "06/06/2020",
//                 "date_remise_dossier": null,
//                 "ref_commande": "CYGELMAN ROGER ET ANNIE",
//                 "ref_projet_client": "",
//                 "commentaire_date_signature": "",
//                 "bloc_administratif_date_1": null,
//                 "bloc_administratif_date_2": null,
//                 "bloc_administratif_date_3": null,
//                 "bloc_administratif_date_4": null,
//                 "bloc_administratif_date_5": null,
//                 "bloc_administratif_date_6": null,
//                 "bloc_administratif_date_7": "03/07/2020",
//                 "bloc_administratif_date_8": "18/10/2020",
//                 "bloc_administratif_date_9": null,
//                 "bloc_administratif_date_10": "10/03/2022",
//                 "bloc_administratif_date_11": null,
//                 "bloc_administratif_date_12": null,
//                 "bloc_administratif_date_13": null,
//                 "bloc_administratif_date_14": null,
//                 "bloc_administratif_date_15": null,
//                 "bloc_administratif_date_16": null,
//                 "bloc_administratif_date_17": null,
//                 "bloc_administratif_date_18": null,
//                 "bloc_administratif_date_19": null,
//                 "bloc_administratif_date_20": null,
//                 "bloc_administratif_bool_1": "0",
//                 "bloc_administratif_bool_2": "0",
//                 "bloc_administratif_bool_3": "1",
//                 "bloc_administratif_bool_4": "0",
//                 "bloc_administratif_bool_5": "0",
//                 "bloc_administratif_bool_6": "0",
//                 "bloc_administratif_bool_7": "0",
//                 "bloc_administratif_bool_8": "0",
//                 "bloc_administratif_bool_9": "0",
//                 "bloc_administratif_bool_10": "0",
//                 "bloc_administratif_bool_11": "0",
//                 "bloc_administratif_bool_12": "0",
//                 "bloc_administratif_bool_13": "0",
//                 "bloc_administratif_bool_14": "0",
//                 "bloc_administratif_bool_15": "0",
//                 "bloc_administratif_bool_16": "0",
//                 "bloc_administratif_bool_17": "0",
//                 "bloc_administratif_chaine_1": "25629956563362",
//                 "bloc_administratif_chaine_2": "18",
//                 "bloc_administratif_chaine_3": "-",
//                 "bloc_administratif_chaine_4": "Client",
//                 "bloc_administratif_chaine_5": "",
//                 "bloc_administratif_chaine_6": "24107301-01",
//                 "bloc_administratif_chaine_7": "",
//                 "bloc_administratif_chaine_8": "",
//                 "bloc_administratif_chaine_9": "",
//                 "bloc_administratif_chaine_10": null,
//                 "bloc_administratif_chaine_11": "",
//                 "bloc_administratif_chaine_12": "",
//                 "bloc_administratif_chaine_13": null,
//                 "bloc_administratif_chaine_14": null,
//                 "bloc_administratif_chaine_15": null,
//                 "bloc_administratif_chaine_16": null,
//                 "bloc_administratif_chaine_17": null,
//                 "bloc_administratif_chaine_18": null,
//                 "bloc_administratif_chaine_19": null,
//                 "bloc_administratif_chaine_20": null,
//                 "bloc_administratif_chaine_21": null,
//                 "bloc_administratif_chaine_22": null,
//                 "bloc_administratif_chaine_23": null,
//                 "bloc_administratif_chaine_24": null,
//                 "bloc_administratif_chaine_25": null,
//                 "bloc_administratif_chaine_26": null,
//                 "bloc_administratif_chaine_27": null,
//                 "bloc_administratif_chaine_28": null,
//                 "bloc_administratif_dec_1": "0.00",
//                 "bloc_administratif_dec_2": "260.00",
//                 "bloc_administratif_dec_3": "10500.00",
//                 "bloc_administratif_dec_4": "6.00",
//                 "bloc_administratif_dec_5": "0.00",
//                 "bloc_administratif_dec_6": "6.48",
//                 "bloc_administratif_dec_7": "4.20",
//                 "bloc_administratif_dec_8": "0.00",
//                 "bloc_administratif_text_1": null,
//                 "bloc_administratif_commentaire": "<strong> - 12/05/2023 13:53 par DIKONGUE: </strong>Eu le client ce jour et Relance Envoi de mail ce jour pour avoir docs pour facturation.\r\nDemande d'avenant de changer de contrat\r\n<br /><strong> - 10/03/2022 10:33 par SERVICE CLIENT: </strong>regul_facture_2021_FO00291_CYGELMAN<br /><strong> - 19/10/2020 06:39 par COLAS: </strong>mail de bienvenue<br /><strong> - 18/10/2020 10:00 par COLAS: </strong>Passage en fourniture<br /><strong> - 15/10/2020 07:01 par MELLE: </strong>Mail montant échéance le 28/08/20<br />",
//                 "fk_statut_financement": "0",
//                 "libelle_statut_financier": "Non concerné",
//                 "categorie_revenu": null,
//                 "modalite_financement": null,
//                 "bool_reglement_avant_liv": "0",
//                 "bool_qui_facturer": "0",
//                 "commentaire_financement": null,
//                 "fk_statut_previsite": "0",
//                 "libelle_statut_previsite": "Sans statut",
//                 "date_previsite_prev": null,
//                 "date_previsite_reelle": null,
//                 "bloc_previsite_chaineradio_1": null,
//                 "commentaire_previsite": null,
//                 "fk_statut_commande": "0",
//                 "libelle_statut_commande": "Sans statut",
//                 "fk_statut_installation": "1",
//                 "libelle_statut_installation": "A statuer",
//                 "fk_transporteur_livraison": null,
//                 "fk_poseur_installation": "11",
//                 "fk_tech_installation": null,
//                 "date_livraison_client_prev": null,
//                 "date_livraison_client_reelle": null,
//                 "bloc_install_date_1": null,
//                 "bloc_install_date_2": null,
//                 "bloc_install_date_3": "09/03/2022",
//                 "bloc_install_date_4": "22/06/2020",
//                 "bloc_install_date_5": null,
//                 "bloc_install_date_6": "22/07/2020",
//                 "bloc_install_date_7": "28/08/2020",
//                 "bloc_install_date_8": "30/07/2020",
//                 "bloc_install_date_9": null,
//                 "bloc_install_date_10": null,
//                 "date_install_prev": null,
//                 "date_install_prev_fin": null,
//                 "date_install_reelle": null,
//                 "date_install_reelle_fin": null,
//                 "date_install_coupure_debut": null,
//                 "date_install_coupure_fin": null,
//                 "validation_date_install": "0",
//                 "commentaire_installation": "<strong> - 09/03/2022 15:22 par APARICIO: </strong>ARPE RECU <br /><strong> - 03/03/2022 15:04 par APARICIO: </strong>ARPE AUGMENTED ENERGY ENVOYE CE JOUR <br />",
//                 "information_installation": null,
//                 "bloc_install_commentaire_1": null,
//                 "bloc_install_commentaire_2": null,
//                 "fk_statut_ctrl_installation": "0",
//                 "libelle_statut_ctrlinstallation": "Non concernée",
//                 "date_ctrl_installation_prev": null,
//                 "date_ctrl_installation_reelle": null,
//                 "commentaire_ctrl_installation": null,
//                 "fk_devise": "1",
//                 "libelle_devise": "€",
//                 "pourcent_tva": "20.00",
//                 "bloc_financier_chaine_1": null,
//                 "bloc_financier_date_1": null,
//                 "bloc_financier_date_2": null,
//                 "facture_num": null,
//                 "facture_montant": "599.00",
//                 "facture_montant_regle": "0.00",
//                 "facture_date_emission": null,
//                 "facture_date_reglement": null,
//                 "montant_frais_port": "0.00",
//                 "montant_origine_affaire": "0.00",
//                 "remise_client": "0.00",
//                 "accompte_da": "0.00"
//             }
//         ]
//     }
// }
          this.loading = false
          
          if(result.data.statut == 'OK') {
            console.log(result.data.statut)
            this.situation = result.data.data_situation
          }         
        } catch (error) {
          this.loading = false
            console.log(error)
        }
        
        
      },
    },
    mounted() {

      if(this.$route.params.idOrganisation) {
        this.idOrganisation = this.$route.params.idOrganisation
      }
      this.loading = true
      this.loadSituation(this.idOrganisation)
    }
  }
</script>


<style>

</style>