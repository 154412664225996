<template>
  <b-container class="pt-2" fluid="md">
    <h3 class="mt-4">PRÉVISITE TECHNIQUE ACTIVITÉ PHOTOVOLTAÏQUE</h3>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <!-- Tabs with card integration -->
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset="onReset">
    <b-card no-body>
      <b-tabs v-model="tabIndex" small card pills>
        <!-- <b-tab title="Informations cient" :title-link-class="linkClass('info')" @click="selectedTab='info'">
          <b-form-row>
            <b-col md="6">
              <TextInput id="nom" type="text" label="Nom" :rules="{ required: true, min: 3 }" v-model="informations.nom"></TextInput>
            </b-col>
            <b-col md="6">
                 <TextInput id="prenom" type="text" label="Prénom" :rules="{ required: true, min: 3 }" v-model="informations.prenom"></TextInput>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="12">
              <TextInput id="adresse" type="text" label="Adresse" :rules="{ required: true, min: 3 }" v-model="informations.adresse"></TextInput>             
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="6">
             <TextInput id="ville" type="text" label="Ville" :rules="{ required: true, min: 3 }" v-model="informations.ville"></TextInput>             
            </b-col>
            <b-col md="3">
              <TextInput id="codePostal" type="number" label="Code postal" :rules="{ required: true, min: 5 }" v-model="informations.codePostal"></TextInput>             
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="6">
              <TextInput id="email" type="email" label="Email" :rules="{ required: true, email: true}" v-model="informations.email"></TextInput>             
            </b-col>
            <b-col md="3">
              <TextInput id="telephone" type="tel" label="Tél." :rules="{ required: false, min: 10 }" v-model="informations.telephone"></TextInput>             
            </b-col>
            <b-col md="3">
              <TextInput id="mobile" type="tel" label="Mobile" :rules="{ required: true, min: 10 }" v-model="informations.mobile"></TextInput>                 
            </b-col>
          </b-form-row>
     
        </b-tab> -->
        <!-- <template #tabs-start>
          <b-form-select @change="onChange()" v-model="tabIndex">
            <b-form-select-option v-for="(bloc,index) in blocs" :key="bloc.id" :value="index">{{bloc.title}}</b-form-select-option>
              <template #first>
                <b-form-select-option value="" disabled>-- Choisir --</b-form-select-option>
              </template>
          </b-form-select>
        </template> -->
        

        <BlocItem v-for="bloc in blocs" :key="bloc.id" :bloc="bloc"></BlocItem>

        <!-- <b-tab title="Dossier" :title-link-class="linkClass('dossier')" @click="selectedTab='dossier'">
          <b-form-row>
            <b-col md="12">
              <CreationDossier :informations="informations" :blocs="blocs" :itemRequired="false" @onCreated="resetVT()"/>
            </b-col>
          </b-form-row>
        </b-tab> -->
      </b-tabs>
    </b-card>
    </b-form>
    </validation-observer>
  </b-container>
</template>

<script>
//import TextInput from "../components/TextInput";
//import CreationDossier from '../components/CreationDossier'
import DossierService from '../services/DossierService'
import BlocItem from '../components/BlocItem'
import json from '../models/model.json'


export default {
  name: 'VT',
  components: {
    //TextInput,
    //CreationDossier,
    BlocItem
  },
  data: () => ({
      index: 1,
      tabIndex: 0,      
      selectedTab: "info",
      show: true,
      // informations : {
      //   nom : '',
      //   prenom :'',
      //   adresse :'',
      //   codePostal :null,
      //   ville :'',
      //   mobile: null,
      //   telephone: null,
      //   email: '',        
      // },
      blocs : json
  }),
  mounted(){
    console.log(this.$route.params)
    console.log(this.blocs)
    if(this.$route.params && this.$route.params.vtId) {
      DossierService.GetVT(this.$store.state.auth.user.id_user_membre, 39).then(response => {
        console.log(response.data.dataVt.json_vt_string)
        this.blocs = JSON.parse(response.data.dataVt.json_vt_string)
        this.editMode = true;
       //this.blocs = response
      })
    }
  },
  methods: {
    linkClass(tab) {
        if (this.selectedTab === tab) {
          return ['bg-primary', 'text-light']
        } else {
          return ['bg-light', 'text-primary']
        }
      },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit (evt) {
      evt.preventDefault()
      alert(JSON.stringify(this.form))
    },
    resetVT() {
      console.log("resetVT")
      this.blocs.forEach(bloc => {
        bloc.questions.forEach(question => {
          Array.isArray(question.value) ? question.value = [] : question.value = null
          if(question.sub_questions)
          {
            question.sub_questions.forEach(subQuestion => {
              Array.isArray(subQuestion.value) ? subQuestion.value = [] : subQuestion.value = null
            })
          }
        })        
      });

      this.$store.dispatch("viderDocuments")
    },
    onReset (evt) {
      evt.preventDefault()
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    onChange() {
      console.log(this.tabIndex)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: white;
}
legend {
  font-size: 16px;
}
</style>
