const state =  {
  stages: null
};

const getters = {
  stages : state => { return state.stages},
 }

const mutations = {
  SET_WORKFLOW(state, stages) {
    state.stages = stages
  }
}

const actions = {
  setWorkflow(context, stages) {
    context.commit("SET_WORKFLOW", stages);
  }
}

export default {
    state, getters, mutations, actions
}