<template>
  <div>
      <h4>{{ bloc.title }}</h4>
      <b-form-row>
        <b-col md="10">
          <QuestionItem v-for="question in bloc.questions" :key="question.id" :question="question" :blocId="bloc.id"></QuestionItem>
        </b-col>
      </b-form-row>
  </div>
</template>

<script>
import QuestionItem from "../components/QuestionItem"

export default {
  name: 'BlocItem',
  components: {
      QuestionItem
  },
  props: ['bloc', 'selectedTab'],
  data: () => ({
    fatalErrorMsg : ""
  }),
  methods: {
    linkClass(tab) {
        if (this.selectedTab === tab) {
          return ['bg-primary', 'text-light']
        } else {
          return ['bg-light', 'text-primary']
        }
      },
		getValidationState({ dirty, validated, valid = null }) {
			return dirty || validated ? valid : null;
		}
  }
}
</script>

<style>

</style>