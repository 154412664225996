import Vue from 'vue'
import Router from 'vue-router'
import Login from "@/views/Login"
import Signup from "@/views/Signup"
import VT from "@/views/VT"
import Workflow from "@/views/Workflow"
import Client from "@/views/Client"
import Situation from "@/views/Situation"

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'Login',
      component: Login
    },
    {
      path: '/signup',
      name: 'Signup',
      component: Signup
    }
    ,
    {
      path: '/vt',
      name: 'vt',
      component: VT
    }
    ,
    {
      path: '/wf',
      name: 'wf',
      component: Workflow
    }
    ,
    {
      path: '/client/:tel?',
      name: 'client',
      component: Client
    }
    ,
    {
      path: '/client/:idOrganisation/situation',
      name: 'situation',
      component: Situation
    }
  ]
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/signup'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if(to.path =="/" && loggedIn != null)
  {
    next("/wf")
    
  } else 
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/');
  } else {
    next();
  }
});

export default router