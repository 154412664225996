<template>
  <div class="container mt-5 mb-5" fluid="sm">  
    

    <d-stepper :stages="stages">
          <template #fatal-error="{ errorMsg }">{{ errorMsg }}</template>
    </d-stepper>


  </div>
</template>

<script>

import DStepper from "@/components/DStepper";
import CreationDossier from "@/components/CreationDossier"

  export default {
    data() {
      return {
        stages : [ 
          {
          id: 0, 
          name: 'Contact',
          icon : "fas fa-light fa-address-card",
          steps : [
            { id: 1, name: 'Est-ce une entreprise ?', completed: false, type:"radio", list_values : [{text:"Oui", value:{ text:"Oui", stepId: 2}}, {text:"Non" , value: { text:"Non", stepId: 3}}], selected: null, component: null },
            { id: 2, name: 'Saisie des informations de l\'entreprise', completed: false, nextId: 4, component:null, 
                form: [{
                        id: 1,
                        title: "",
                        questions: [
                            {
                                "id": 1,
                                "title": "Siret ",
                                "comment": null,
                                "question_type": "inputText",
                                "value_type": "number",
                                "default_value": null,
                                "list_values": [],
                                "value": null,
                                "selected_parent_value": null,
                                "sub_questions": [],
                                "validation" : { digits:14 }
                            },
                            {
                                "id": 2,
                                "title": "Nom société ",
                                "comment": null,
                                "question_type": "inputText",
                                "value_type": "text",
                                "default_value": null,
                                "list_values": [],
                                "value": null,
                                "selected_parent_value": null,
                                "sub_questions": [],
                                "validation" : "required"
                            },
                            {
                                "id": 3,
                                "title": "Adresse société ",
                                "comment": null,
                                "question_type": "inputText",
                                "value_type": "text",
                                "default_value": null,
                                "list_values": [],
                                "value": null,
                                "selected_parent_value": null,
                                "sub_questions": [],
                                "validation" : "required"
                            },
                            {
                                "id": 4,
                                "title": "Code postal ",
                                "comment": null,
                                "question_type": "inputText",
                                "value_type": "number",
                                "default_value": null,
                                "list_values": [],
                                "value": null,
                                "selected_parent_value": null,
                                "sub_questions": [],
                                "validation" : {required:true, digits:5}
                            },
                            {
                                "id": 5,
                                "title": "Ville ",
                                "comment": null,
                                "question_type": "inputText",
                                "value_type": "text",
                                "default_value": null,
                                "list_values": [],
                                "value": null,
                                "selected_parent_value": null,
                                "sub_questions": [],
                                "validation" : "required"
                            },
            // {
            //     "id": 6,
            //     "title": "PDL",
            //     "comment": null,
            //     "question_type": "inputText",
            //     "value_type": "text",
            //     "default_value": null,
            //     "list_values": [],
            //     "value": null,
            //     "selected_parent_value": null,
            //     "sub_questions": []
            // }
        ]
             }]
            },
            { id: 3, name: 'Saisie des informations du client', completed: false, nextId: 4, component:null, 
            form : [
            {
            "id": 1,
            "title": "",
            "questions": [
              {
                "id": 1,
                "title": "Civilité ",
                "comment": null,
                "question_type": "select",
                "value_type": "select",
                "default_value": null,
                "list_values": ['M.', 'Mme'],
                "value": null,
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : "required"
            },
            {
                "id": 2,
                "title": "Nom",
                "comment": null,
                "question_type": "inputText",
                "value_type": "text",
                "default_value": null,
                "list_values": [],
                "value": null,
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : "required"
            },
            {
                "id": 3,
                "title": "Prénom",
                "comment": null,
                "question_type": "inputText",
                "value_type": "text",
                "default_value": null,
                "list_values": [],
                "value": null,
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : "required"
            },
            {
                "id": 4,
                "title": "Téléphone",
                "comment": null,
                "question_type": "inputText",
                "value_type": "number",
                "default_value": null,
                "list_values": [],
                "value": null,
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : {required:true, digits:10}
            },
            {
                "id": 5,
                "title": "Mail",
                "comment": null,
                "question_type": "inputText",
                "value_type": "text",
                "default_value": null,
                "list_values": [],
                "value": null,
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : {required:true, email:true}
            },
            // {
            //     "id": 6,
            //     "title": "Adresse",
            //     "comment": null,
            //     "question_type": "inputText",
            //     "value_type": "text",
            //     "default_value": null,
            //     "list_values": [],
            //     "value": null,
            //     "selected_parent_value": null,
            //     "sub_questions": []
            // },
            // {
            //     "id": 7,
            //     "title": "Code postal ",
            //     "comment": null,
            //     "question_type": "inputText",
            //     "value_type": "number",
            //     "default_value": null,
            //     "list_values": [],
            //     "value": null,
            //     "selected_parent_value": null,
            //     "sub_questions": []
            // },
            // {
            //     "id": 8,
            //     "title": "Ville ",
            //     "comment": null,
            //     "question_type": "inputText",
            //     "value_type": "text",
            //     "default_value": null,
            //     "list_values": [],
            //     "value": null,
            //     "selected_parent_value": null,
            //     "sub_questions": []
            // },
            // {
            //     "id": 9,
            //     "title": "PDL",
            //     "comment": null,
            //     "question_type": "inputText",
            //     "value_type": "text",
            //     "default_value": null,
            //     "list_values": [],
            //     "value": null,
            //     "selected_parent_value": null,
            //     "sub_questions": []
            // }
        ]
    },
            ] },
            { id: 4, name: 'Est-ce qu\'il y a un deuxieme contact', completed: false, type:"radio", list_values : [{text:"Oui", value:{ text:"Oui", stepId: 5}}, {text:"Non" , value: { text:"Non", stepId: 6}}], selected: null, component: null },
            { id: 5, name: 'Saisie des informations du deuxieme contact', completed: false, nextId: 6,  component:null, 
            form : [
            {
            "id": 1,
            "title": "",
            "questions": [
              {
                "id": 1,
                "title": "Civilité ",
                "comment": null,
                "question_type": "select",
                "value_type": "select",
                "default_value": null,
                "list_values": ['M.', 'Mme'],
                "value": null,
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : "required"
            },
            {
                "id": 2,
                "title": "Nom",
                "comment": null,
                "question_type": "inputText",
                "value_type": "text",
                "default_value": null,
                "list_values": [],
                "value": null,
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : "required"
            },
            {
                "id": 3,
                "title": "Prénom",
                "comment": null,
                "question_type": "inputText",
                "value_type": "text",
                "default_value": null,
                "list_values": [],
                "value": null,
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : "required"
            },
            {
                "id": 4,
                "title": "Téléphone",
                "comment": null,
                "question_type": "inputText",
                "value_type": "number",
                "default_value": null,
                "list_values": [],
                "value": null,
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : "required"
            },
            {
                "id": 5,
                "title": "Mail",
                "comment": null,
                "question_type": "inputText",
                "value_type": "text",
                "default_value": null,
                "list_values": [],
                "value": null,
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : "required"
            },
            // {
            //     "id": 6,
            //     "title": "Adresse",
            //     "comment": null,
            //     "question_type": "inputText",
            //     "value_type": "text",
            //     "default_value": null,
            //     "list_values": [],
            //     "value": null,
            //     "selected_parent_value": null,
            //     "sub_questions": []
            // },
            // {
            //     "id": 7,
            //     "title": "Code postal ",
            //     "comment": null,
            //     "question_type": "inputText",
            //     "value_type": "number",
            //     "default_value": null,
            //     "list_values": [],
            //     "value": null,
            //     "selected_parent_value": null,
            //     "sub_questions": []
            // },
            // {
            //     "id": 8,
            //     "title": "Ville ",
            //     "comment": null,
            //     "question_type": "inputText",
            //     "value_type": "text",
            //     "default_value": null,
            //     "list_values": [],
            //     "value": null,
            //     "selected_parent_value": null,
            //     "sub_questions": []
            // }
        ]
    },
            ]
          },
            { id: 6, name: 'Saisie des informations complementaires du contact', completed: false, nextId: 8, component:null, 
            form: [{
        id: 1,
        title: "",
        questions: [
            // {
            //     "id": 1,
            //     "title": "Quel est le nombre de personnes dans votre foyer ? ",
            //     "comment": null,
            //     "question_type": "inputText",
            //     "value_type": "number",
            //     "default_value": null,
            //     "list_values": [],
            //     "value": null,
            //     "selected_parent_value": null,
            //     "sub_questions": []  
            // },
            {
                "id": 2,
                "title": "Comment vous nous avez connu ?",
                "comment": null,
                "question_type": "select",
                "value_type": "select",
                "default_value": null,
                "list_values": ['Site Internet', 'Réseaux sociaux', 'Partenaires', 'Bouche à oreille', 'Parrainage', 'Autres'],
                "value": null,
                "selected_parent_value": null,
                "sub_questions": [
                    {
                      "id": 0,
                      "title": "Lequel ?",
                      "comment": null,
                      "question_type": "select",
                      "value_type": "select",
                      "default_value": null,
                      "list_values": ['Oscaro', 'Monkit solaire', 'Mecafer', 'Partenaire poseur'],
                      "value": null,
                      "selected_parent_value": 'Partenaires',
                      "sub_questions": [],
                      "validation" : ""
                    },
                    {
                      "id": 1,
                      "title": "Précisez ",
                      "comment": null,
                      "question_type": "inputText",
                      "value_type": "text",
                      "default_value": null,
                      "list_values": [],
                      "value": null,
                      "selected_parent_value": 'Autres',
                      "sub_questions": [],
                      "validation" : ""
                  }
                ],
                "validation" : ""
            },
        ]
             }] },
          ]
        },
        {
          id: 1, 
          name: 'Installation',
          icon : "fas fa-car",
          steps : [
            { id: 8, name: 'Est-ce que le client a une installation solaire ?', completed: false, type:"radio", list_values : [{text:"Oui", value:{ text:"Oui", stepId: 9}}, {text:"Non" , value: { text:"Non", stepId: 10}}], selected: null, component: null },
            { id: 9, name: 'Saisie des informations de liee a l\'installation solaire', completed: false, nextId: 11, component:null,
            form: [{
        id: 1,
        title: "",
        questions: [
            {
                "id": 1,
                "title": "Date de l'installation solaire ? ",
                "comment": null,
                "question_type": "date",
                "value_type": "date",
                "default_value": null,
                "list_values": [],
                "value": null,
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : ""
            },
            {
                "id": 2,
                "title": "Phasage",
                "comment": null,
                "question_type": "select",
                "value_type": "select",
                "default_value": null,
                "list_values": ['Monophasé', 'Triphasé'],
                "value": null,
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : "required"
            },
            {
                "id": 3,
                "title": "Puissance solaire kWc",
                "comment": null,
                "question_type": "inputText",
                "value_type": "number",
                "default_value": null,
                "list_values": [],
                "value": null,
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : ""
            },
            // {
            //     "id": 4,
            //     "title": "Puissance des panneaux installés",
            //     "comment": null,
            //     "question_type": "inputText",
            //     "value_type": "number",
            //     "default_value": null,
            //     "list_values": [],
            //     "value": null,
            //     "selected_parent_value": null,
            //     "sub_questions": []
            // },
            // {
            //     "id": 5,
            //     "title": "Avez -vous un contrat de maintenance ?",
            //     "comment": null,
            //     "question_type": "select",
            //     "value_type": "select",
            //     "default_value": null,
            //     "list_values": ['Oui', 'Non'],
            //     "value": null,
            //     "selected_parent_value": null,
            //     "sub_questions": [
            //       {
            //         "id": 0,
            //         "title": "Avec qui ?",
            //         "comment": null,
            //         "question_type": "inputText",
            //         "value_type": "text",
            //         "default_value": null,
            //         "list_values": [],
            //         "value": null,
            //         "selected_parent_value": 'Oui',
            //         "sub_questions": []
            //       },
            //       {
            //         "id": 1,
            //         "title": "Contrat JPME ?",
            //         "comment": null,
            //         "question_type": "inputText",
            //         "value_type": "text",
            //         "default_value": null,
            //         "list_values": [],
            //         "value": null,
            //         "selected_parent_value": 'Non',
            //         "sub_questions": []
            //       }
            //     ]
            // },
            {
                "id": 6,
                "title": "Est-ce que la DP a été faite ?",
                "comment": null,
                "question_type": "select",
                "value_type": "select",
                "default_value": null,
                "list_values": ['Oui', 'Non'],
                "value": null,
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : "required"
            },
            {
                "id": 7,
                "title": "Est-ce que le Consuel est à jour ? ?",
                "comment": null,
                "question_type": "select",
                "value_type": "select",
                "default_value": null,
                "list_values": ['Oui', 'Non'],
                "value": null,
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : "required"
            },
            {
                "id": 8,
                "title": "Est ce que vous disposez d'une batterie physique ?",
                "comment": null,
                "question_type": "select",
                "value_type": "select",
                "default_value": null,
                "list_values": ['Oui', 'Non'],
                "value": null,
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : "required"
            },
        ]
             }]
          },
            { id: 10, name: 'Proposer un RDV pour projet Autonomie', completed: false, nextId: null, component: null ,
            form: [{
        id: 1,
        title: "",
        questions: [
            {
                "id": 0,
                "title": "Date de disponibilité ",
                "comment": null,
                "question_type": "date",
                "value_type": "text",
                "default_value": null,
                "list_values": [],
                "value": null,
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : ""
            },
            {
                "id": 1,
                "title": "Heure de disponibilité ",
                "comment": null,
                "question_type": "time",
                "value_type": "text",
                "default_value": null,
                "list_values": [],
                "value": null,
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : ""
            },
            {
                "id": 2,
                "title": "A quelle échéance souhaitez-vous lancer votre projet ?",
                "comment": null,
                "question_type": "date",
                "value_type": "text",
                "default_value": null,
                "list_values": [],
                "value": null,
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : ""
            },
            {
                "id": 3,
                "title": "Commentez votre projet  ?",
                "comment": null,
                "question_type": "textarea",
                "value_type": "text",
                "default_value": null,
                "list_values": [],
                "value": null,
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : ""
            },
        ]
             }]
          
          
          },
            { id: 11, name: 'Est-ce que l\'adresse de production et de consommation sont les memes ?', completed: false, type:"radio", list_values : [{text:"Oui", value:{ text:"Oui", stepId: 13}}, {text:"Non" , value: { text:"Non", stepId: 12}}], selected: null, component: null },
            { id: 12, name: 'Saisie adresse client', completed: false, nextId: 13, component:null,
            form: [{
        id: 1,
        title: "",
        questions: [
            {
                "id": 3,
                "title": "Adresse",
                "comment": null,
                "question_type": "inputText",
                "value_type": "text",
                "default_value": null,
                "list_values": [],
                "value": "Adresse consomation",
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : "required"
            },
            {
                "id": 4,
                "title": "Code postal ",
                "comment": null,
                "question_type": "inputText",
                "value_type": "number",
                "default_value": null,
                "list_values": [],
                "value": "75009",
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : {required:true, digits:5}
            },
            {
                "id": 5,
                "title": "Ville ",
                "comment": null,
                "question_type": "inputText",
                "value_type": "text",
                "default_value": null,
                "list_values": [],
                "value": "Paris",
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : "required"
            },
            {
                "id": 6,
                "title": "PDL",
                "comment": null,
                "question_type": "inputText",
                "value_type": "text",
                "default_value": null,
                "list_values": [],
                "value": "PDFLCONSO",
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : {required:true, digits:14}
            }
        ]
             }]
          },
            { id: 13, name: 'Saisie adresse installation de production', completed: false, nextId: 14, component:null,
            form: [{
        id: 1,
        title: "",
        questions: [
            {
                "id": 3,
                "title": "Adresse",
                "comment": null,
                "question_type": "inputText",
                "value_type": "text",
                "default_value": null,
                "list_values": [],
                "value": "Adresse facturation",
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : "required"
            },
            {
                "id": 4,
                "title": "Code postal ",
                "comment": null,
                "question_type": "inputText",
                "value_type": "number",
                "default_value": null,
                "list_values": [],
                "value": "75008",
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : {required:true, digits:5}
            },
            {
                "id": 5,
                "title": "Ville ",
                "comment": null,
                "question_type": "inputText",
                "value_type": "text",
                "default_value": null,
                "list_values": [],
                "value": "Paris",
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : "required"
            },
            {
                "id": 6,
                "title": "PDL",
                "comment": null,
                "question_type": "inputText",
                "value_type": "text",
                "default_value": null,
                "list_values": [],
                "value": "PDLFACT",
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : {required:true, digits:14}
            }
        ]
             }]
          },
            { 
                id: 14, name: 'Informations de consomation', completed: false, nextId: 22, component:null,
                form: [{
                id: 1,
                title: "",
                questions: [
                    {
                        "id": 3,
                        "title": "Puissance souscrite (kVA)",
                        "comment": null,
                        "question_type": "inputText",
                        "value_type": "number",
                        "default_value": null,
                        "list_values": [],
                        "value": null,
                        "selected_parent_value": null,
                        "sub_questions": [],
                        "validation" : "required"
                    },
                    {
                        "id": 4,
                        "title": "Nom du fournisseur historique",
                        "comment": null,
                        "question_type": "select",
                        "value_type": "select",
                        "default_value": null,
                        "list_values": ['EDF', 'Engie', 'Total Direct Énergie', 'Eni', 'Greenyellow', 'ekWateur', 'Iberdrola', 'Planète OUI', 'Vattenfall', 'Lucia', 'Autres'],
                        "value": null,
                        "selected_parent_value": null,
                        "sub_questions": [{
                            "id": 0,
                            "title": "Précisez",
                            "comment": null,
                            "question_type": "inputText",
                            "value_type": "number",
                            "default_value": null,
                            "list_values": [],
                            "value": null,
                            "selected_parent_value": 'Autres',
                            "sub_questions": [],
                            "validation" : ""
                        }],
                        "validation" : "required"
                    },
                    {
                        "id": 5,
                        "title": "Montant de la dernière facture (annuelle)",
                        "comment": null,
                        "question_type": "inputText",
                        "value_type": "number",
                        "default_value": null,
                        "list_values": [],
                        "value": null,
                        "selected_parent_value": null,
                        "sub_questions": [],
                        "validation" : "required"
                    },
                    {
                        "id": 6,
                        "title": "Option tarifaire",
                        "comment": null,
                        "question_type": "select",
                        "value_type": "select",
                        "default_value": null,
                        "list_values": ['base', 'tarif HC/HP'],
                        "value": null,
                        "selected_parent_value": null,
                        "sub_questions": [],
                        "validation" : "required"
                    }
                ]
             }]
            },
            // { 
            //     id: 15, name: 'Etes-vous interesse par l\'efficacite energetique ?', completed: false, type:"radio", list_values : [{text:"Oui", value:{ text:"Oui", stepId: 17}}, {text:"Non" , value: { text:"Non", stepId: 23}}], selected: null, component: null 
            // },
            ]
        },
        // {
        //   id: 2, 
        //   name: 'Efficacite energetique',
        //   icon : "fas fa-car",
        //   steps : [
        //     { id: 17, name: 'Saisie infos maison', completed: false, nextId: 18, component: null,
        //     form: [{
        // id: 1,
        // title: "",
        // questions: [
        //     {
        //         "id": 1,
        //         "title": "Quelle est la superficie de votre maison (m2) ?",
        //         "comment": null,
        //         "question_type": "inputText",
        //         "value_type": "number",
        //         "default_value": null,
        //         "list_values": [],
        //         "value": null,
        //         "selected_parent_value": null,
        //         "sub_questions": []
        //     },
        //     {
        //         "id": 4,
        //         "title": "Combien de personnes y habitent ?",
        //         "comment": null,
        //         "question_type": "inputText",
        //         "value_type": "number",
        //         "default_value": null,
        //         "list_values": [],
        //         "value": null,
        //         "selected_parent_value": null,
        //         "sub_questions": []
        //     }
        // ]
        //      }]
        //     },
        //     { id: 18, name: 'Saisie infos chauffage', completed: false, nextId: 19, component:null, 
        //     form: [{
        // id: 1,
        // title: "",
        // questions: [
        //     {
        //         "id": 1,
        //         "title": "Quel est le système de chauffage utilisé ?",
        //         "comment": null,
        //         "question_type": "select",
        //         "value_type": "select",
        //         "default_value": null,
        //         "list_values": ['Chauffage central au gaz', 'Chauffage central au fioul', 'Chauffage électriques', 'Pac air-air', 'Pac air-eau', 'Plancher chauffant'],
        //         "value": null,
        //         "selected_parent_value": null,
        //         "sub_questions": []
        //     },
        //     {
        //         "id": 2,
        //         "title": "Quelle est sa performance du système de chauffage ?",
        //         "comment": null,
        //         "question_type": "select",
        //         "value_type": "select",
        //         "default_value": null,
        //         "list_values": ['Performant', 'Fonctionnel', 'Défectueux', 'En panne'],
        //         "value": null,
        //         "selected_parent_value": null,
        //         "sub_questions": []
        //     },
        //     {
        //         "id": 3,
        //         "title": "Quel est l'âge du système de chauffage ? ",
        //         "comment": null,
        //         "question_type": "inputText",
        //         "value_type": "number",
        //         "default_value": null,
        //         "list_values": [],
        //         "value": null,
        //         "selected_parent_value": null,
        //         "sub_questions": []
        //     },
        //     {
        //         "id": 4,
        //         "title": "Quel est la température moyenne en hiver dans votre logement ? ",
        //         "comment": null,
        //         "question_type": "inputText",
        //         "value_type": "number",
        //         "default_value": null,
        //         "list_values": [],
        //         "value": null,
        //         "selected_parent_value": null,
        //         "sub_questions": []
        //     }
            
        // ]
        //      }]
        //     },
        //     { id: 19, name: 'Saisie infos eau chaude', completed: false, nextId: 20, component:null, 
        //     form: [{
        // id: 1,
        // title: "",
        // questions: [
        //     {
        //         "id": 1,
        //         "title": "Quel est le système de production d'eau chaude utilisé ?",
        //         "comment": null,
        //         "question_type": "select",
        //         "value_type": "select",
        //         "default_value": null,
        //         "list_values": ['Chauffe-eau électrique', 'Chauffe-eau thermodynamique', 'Chaudière', 'Chauffe-eau à pompe à chaleur', 'Autres'],
        //         "value": null,
        //         "selected_parent_value": null,
        //         "sub_questions": [
        //           {
        //             "id": 2,
        //             "title": "Précisez",
        //             "comment": null,
        //             "question_type": "inputText",
        //             "value_type": "text",
        //             "default_value": null,
        //             "list_values": [],
        //             "value": null,
        //             "selected_parent_value": "Autres",
        //             "sub_questions": []
        //         }

        //         ]
        //     }
        //   ]
        //      }]
        //     },
        //     { id: 20, name: 'Saisie infos rafraichissement', completed: false, nextId: 21,  component: null, 
        //     form: [{
        // id: 1,
        // title: "",
        // questions: [
        //     {
        //         "id": 1,
        //         "title": "Quel est le système de refroidissement/climatisation utilisé ?",
        //         "comment": null,
        //         "question_type": "select",
        //         "value_type": "select",
        //         "default_value": null,
        //         "list_values": ['Aucun', 'Climatisation centrale / gainable', 'Split / Pac air-air', 'Ventilateurs', 'Plancher rafraîchissant'],
        //         "value": null,
        //         "selected_parent_value": null,
        //         "sub_questions": []
        //     },
        //     {
        //         "id": 2,
        //         "title": "Quelle est la performance du système de refroidissement ?",
        //         "comment": null,
        //         "question_type": "select",
        //         "value_type": "select",
        //         "default_value": null,
        //         "list_values": ['Performant', 'Fonctionnel', 'Défectueux', 'En panne'],
        //         "value": null,
        //         "selected_parent_value": null,
        //         "sub_questions": []
        //     },
        //     {
        //         "id": 3,
        //         "title": "Quel est l'âge du système de refroidissement ? ",
        //         "comment": null,
        //         "question_type": "inputText",
        //         "value_type": "number",
        //         "default_value": null,
        //         "list_values": [],
        //         "value": null,
        //         "selected_parent_value": null,
        //         "sub_questions": []
        //     },
        //     {
        //         "id": 4,
        //         "title": "Quelle est la température moyenne en été dans votre logement ?",
        //         "comment": null,
        //         "question_type": "inputText",
        //         "value_type": "number",
        //         "default_value": null,
        //         "list_values": [],
        //         "value": null,
        //         "selected_parent_value": null,
        //         "sub_questions": []
        //     }
            
        // ]
        //      }]
        //   },
        //     { id: 21, name: 'Saisie Info isolation', completed: false, nextId: 22, component:null,
        //     form: [{
        // id: 1,
        // title: "",
        // questions: [
        //     {
        //         "id": 1,
        //         "title": "Quel est le type d'isolation présent dans la maison (murs, toit, fenêtres) ?",
        //         "comment": null,
        //         "question_type": "section",
        //         "value_type": "section",
        //         "default_value": null,
        //         "list_values": [],
        //         "value": null,
        //         "selected_parent_value": null,
        //         "sub_questions": []
        //     },
        //     {
        //         "id": 2,
        //         "title": "Murs",
        //         "comment": null,
        //         "question_type": "select",
        //         "value_type": "select",
        //         "default_value": null,
        //         "list_values": ['Isolé', 'Pas isolé', 'Ne sais pas'],
        //         "value": null,
        //         "selected_parent_value": null,
        //         "sub_questions": []
        //     },
        //     {
        //         "id": 3,
        //         "title": "Toit",
        //         "comment": null,
        //         "question_type": "select",
        //         "value_type": "select",
        //         "default_value": null,
        //         "list_values": ['Isolé', 'Pas isolé', 'Ne sais pas'],
        //         "value": null,
        //         "selected_parent_value": null,
        //         "sub_questions": []
        //     },
        //     {
        //         "id": 4,
        //         "title": "Fenetres",
        //         "comment": null,
        //         "question_type": "select",
        //         "value_type": "select",
        //         "default_value": null,
        //         "list_values": ['Isolé', 'Pas isolé', 'Ne sais pas'],
        //         "value": null,
        //         "selected_parent_value": null,
        //         "sub_questions": []
        //     },
        //     {
        //         "id": 5,
        //         "title": "Y a-t-il des fuites d'air ou des infiltrations dans la maison ?",
        //         "comment": null,
        //         "question_type": "radio",
        //         "value_type": "radio",
        //         "default_value": null,
        //         "list_values": ['Oui', 'Non'],
        //         "value": null,
        //         "selected_parent_value": null,
        //         "sub_questions": [
        //           {
        //             "id": 1,
        //             "title": "Precisez",
        //             "comment": null,
        //             "question_type": "inputText",
        //             "value_type": "text",
        //             "default_value": null,
        //             "list_values": [],
        //             "value": null,
        //             "selected_parent_value": 'Oui',
        //             "sub_questions": []
        //         }
        //         ]
        //     }
            
            
        // ]
        //      }]
        //     },          
        //   ]
        // },
        {
          id: 3, 
          name: 'Achat de surplus',
          icon : "fas fa-car",
          steps : [
            { id: 22, name: 'Saisie infos e-batteries', completed: false, nextId: 23, component:null, 
            form: [{
        id: 1,
        title: "",
        questions: [
            {
                "id": 1,
                "title": "Souhaitez vous souscrire à une solution de rachat de surplus solaire ?",
                "comment": null,
                "question_type": "select",
                "value_type": "select",
                "default_value": null,
                "list_values": ['E-batterie TRV', 'E-batterie Super +', 'E-batterie Super Pro'],
                "value": null,
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : "required"
            }
          ]
             }]
          },
            { id: 23, name: 'Saisie options e-batteries', completed: false, nextId: 24, component:null, 
            form: [{
        id: 1,
        title: "",
        questions: [
            {
                "id": 1,
                "title": "Devons-nous nous occuper de la DP ?",
                "comment": null,
                "question_type": "radio",
                "value_type": "radio",
                "default_value": null,
                "list_values": ['Oui', 'Non'],
                "value": null,
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : "required"
            },
            {
                "id": 2,
                "title": "Voulez-vous souscrire a l'option Consuel JPME ?",
                "comment": null,
                "question_type": "radio",
                "value_type": "radio",
                "default_value": null,
                "list_values": ['Oui', 'Non'],
                "value": null,
                "selected_parent_value": null,
                "validation" : "required",
                "sub_questions": []
            },
            // {
            //     "id": 3,
            //     "title": "Souhaitez-vous une attestation ?",
            //     "comment": null,
            //     "question_type": "select",
            //     "value_type": "select",
            //     "default_value": null,
            //     "list_values": ['Pas d’attestation', 'Attestation bleue', 'Attestation violette'],
            //     "value": null,
            //     "selected_parent_value": null,
            //     "sub_questions": []
            // },
            
        ]
             }]
            },
            { id: 24, name: 'Saisie code promo', completed: false, nextId: 25, component:null ,
            form: [{
        id: 1,
        title: "",
        questions: [
            {
                "id": 1,
                "title": "Disposez-vous d’un code de réduction d’un de nos partenaires ?",
                "comment": null,
                "question_type": "inputText",
                "value_type": "text",
                "default_value": null,
                "list_values": [],
                "value": null,
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : ""
            }
          ]
             }]
          },
            { id: 25, name: 'Commentaire projet', completed: false, nextId: 26, component:null ,
            form: [{
        id: 1,
        title: "",
        questions: [
            {
                "id": 1,
                "title": "Avez-vous des commentaires à ajouter à cette première étude de vos besoins ?",
                "comment": null,
                "question_type": "textarea",
                "value_type": "textarea",
                "default_value": null,
                "list_values": [],
                "value": null,
                "selected_parent_value": null,
                "sub_questions": [],
                "validation" : ""
            }
          ]
             }]
          },         
          ]
        },
        {
          id: 4, 
          name: 'Contrat',
          icon : "fas fa-contract",
          steps : [
            { id: 26, name: 'Contrat PDF', completed: false, nextId: null, component: CreationDossier }           
          ]
        },     
      ]
     }
    },
    components: {
        DStepper
    },
    watch: {
      stages: {
        handler() {

            //La dp a ete faite
            // if(this.stages[1].steps[1].form[0].questions[5].value == 'Oui') {
            //     this.stages[3].steps[1].form[0].questions[0].value = 'Oui'
            // }

            //Consuel a jour
            // if(this.stages[1].steps[1].form[0].questions[6].value == 'Oui') {
            //     this.stages[3].steps[1].form[0].questions[1].value = 'Oui'
            // }

            //Batterie physique
            //if(this.stages[0].steps[1].form[0].questions[7].value == 'Oui') {
            //    this.stages[3].steps[1].form[0].questions[2].value = 
            //}

            //this.$store.dispatch("setWorkflow", this.stages)
        },
        deep:true
      }
    },
  }
</script>


<style>

</style>